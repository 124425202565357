<template>
    <div class="page-phone-btn" :class="{'active': isListOpen}" v-if="Object.keys(pageContent).length">
        <div class="phones-list">   
            <a :href="'tel: ' + pageContent.header.phone" class="item visible-flex-xs hidden-sm">
                <img src="./../assets/images/icons/lifecell-logo.png" alt="">
                {{ pageContent.header.phone }}
            </a>
            <a href="viber://chat?number=+380676613722" class="item visible-flex-sm hidden-xs">
                <span class="fab fa-viber"></span>
                {{ pageTexts.viberBtnText[lang] }}
            </a>
        </div>
        <a class="phone-btn" @click="isListOpen = !isListOpen">
            <span class="fas fa-phone visible-xs hidden-sm"></span>
            <span class="fas fa-envelope visible-sm hidden-xs"></span>
        </a>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isListOpen: false,
            pageTexts: {
                viberBtnText: {
                    ua: 'Написати у Viber',
                    ru: 'Написать в Viber',
                    en: 'White to Viber'
                }
            }
        }
    },  
    computed: {
        lang() {
            return this.$store.getters['getSelectedLang'];
        },
        pageContent() {
            return this.$store.getters['getGeneralPageContent'];
        }
    }
}
</script>
